<template>
  <div class="takeAwayOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-input v-model="form.orderNumber" placeholder="请输入订单编号" style="width:200px;" clearable></el-input>
          <el-select v-model="form.payStatus" placeholder="支付状态" clearable class="ml20" style="width:140px;">
            <el-option v-for="item in payStatusList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.spreadId" placeholder="请选择归属分公司" clearable filterable remote class="ml20"
            :loading="loading" :remote-method="searchBranchOffice" @change="spreadIdChange">
            <!--  @focus="searchBranchOffice('')" -->
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.agentSid" placeholder="请选择代理商" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchAgent" @change="agentSidChange">
          <!--  @focus="searchAgent('')" -->
            <el-option v-for="item in agentList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.shopId" placeholder="请选择商户" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchShop">
          <!--  @focus="searchShop('')" -->
            <el-option v-for="item in  shopList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.distributionPlatform" class="ml20" clearable placeholder="请选择配送方式" style="width:170px;">
            <el-option
              v-for="item in distributionPlatformOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="time"
            class="ml20"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="orderDetail">
      <span>订单总金额：{{orderAmount}}</span>
      <span>订单总笔数：{{orderNum}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderNumber" label="订单编号">
          <template slot-scope="scope">
            <span class="anticipate" v-if="scope.row.isPredict == 2">预</span>
            <span>{{scope.row.orderNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
        <el-table-column prop="contactTel" label="客户电话" width="120"></el-table-column>
        <!-- <el-table-column prop="distributionDiscount" label="优惠">
          <template slot-scope="scope">
            <span>{{scope.row.distributionDiscount / 100}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="spreadName" label="所属分公司"></el-table-column>
        <el-table-column prop="addition" label="附加" width="60px">
          <template slot-scope="scope">
            <span>{{scope.row.addition / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pakage" label="打包" width="60px">
          <template slot-scope="scope">
            <span>{{scope.row.pakage / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="distribution" label="配送" width="70px">
          <template slot-scope="scope">
            <span>{{scope.row.distribution / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间" width="160">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="distributionPlatform" label="送达方式">
          <template slot-scope="scope">
            <span v-if="scope.row.distributionPlatform == 0">商家自配送</span>
            <span v-else-if="scope.row.distributionPlatform == 1">达达配送</span>
            <span v-else-if="scope.row.distributionPlatform == 2">蜂鸟配送</span>
            <span v-else-if="scope.row.distributionPlatform == 4">美团配送</span>
            <span v-else-if="scope.row.distributionPlatform == 5">松鼠配送</span>
            <span v-else-if="scope.row.distributionPlatform == 6">顺丰配送</span>
          </template>
        </el-table-column>
        <el-table-column prop="payMethod" label="支付">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 2">线上支付</span>
            <span v-if="scope.row.payMethod == 3">钱包支付</span>
            <span v-if="scope.row.payMethod == 4">组合支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="printState" label="打印状态">
          <template slot-scope="scope">
            <span v-if="scope.row.printState == 1">待打印</span>
            <span v-if="scope.row.printState == 2">已打印</span>
            <span v-if="scope.row.printState == 3">打印失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待接单</span>
            <span v-if="scope.row.state == 2">骑手待接单</span>
            <span v-if="scope.row.state == 3">待收货</span>
            <span v-if="scope.row.state == 4">配送完成</span>
            <span v-if="scope.row.state == 5">撤销</span>
          </template>
        </el-table-column>
        <el-table-column prop="scoreDeduction" label="抵扣" width="60px">
          <template slot-scope="scope">
            <span>{{scope.row.scoreDeduction / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="小计" width="80px">
          <template slot-scope="scope">
            <span>{{scope.row.amount / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总计">
          <template slot-scope="scope">
            <span>{{scope.row.totalPrice / 100}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="+scope.row.state !== 5" @click="cancelOrder(scope.row)">强制撤销</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sg_findTakeAwayOrder, $sp_agentList, $sp_spreadList, $sp_shopInfoList, $sg_orderStatic, $sp_cancelOrder,
} from '@/api/order';

export default {
  data() {
    return {
      form: {
        orderNumber: '',
        spreadId: '',
        agentSid: '',
        shopId: '',
        payStatus: '',
        distributionPlatform: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      payStatusList: [
        {
          value: 2,
          label: '已支付',
        },
        {
          value: 3,
          label: '支付后退款',
        },
      ],
      distributionPlatformOptions: [
        {
          value: 0,
          label: '商家自配送',
        },
        {
          value: 1,
          label: '达达配送',
        },
        {
          value: 2,
          label: '蜂鸟配送',
        },
        {
          value: 4,
          label: '美团配送',
        },
        {
          value: 5,
          label: '松鼠配送',
        },
        {
          value: 6,
          label: '顺丰配送',
        },
      ],
      orderAmount: 0,
      orderNum: 0,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        orderNumber: '',
        spreadId: '',
        agentSid: '',
        shopId: '',
        payStatus: '',
        distributionPlatform: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      if (this.time) {
        if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
          this.$message.error('查询时间必须在同一个月内');
          return;
        }
        params.startTimeCreate = this.$formatDateByDate(this.time[0], 'yyyy-MM-dd');
        params.endTimeCreate = this.$formatDateByDate(this.time[1], 'yyyy-MM-dd');
      } else {
        params.startTimeCreate = '';
        params.endTimeCreate = '';
      }
      // 请求订单数据
      $sg_findTakeAwayOrder(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单汇总
      $sg_orderStatic(params).then((res) => {
        this.orderAmount = res.orderAmount || 0;
        this.orderNum = res.orderNum || 0;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    spreadIdChange() {
      this.form.agentSid = '';
      this.form.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 强制撤销订单
    cancelOrder(row) {
      this.$confirm('确定要强制撤销此订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = {
          order_id: row.orderId,
          cancel_reason: '',
          force: 1,
        };
        $sp_cancelOrder(params).then(() => {
          this.getTableData();
        });
      }).catch(() => {});
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.takeAwayOrder{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail{
    width: 100%;
    margin-top: 40px;
    padding:20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child{
      margin-right: 80px;
    }
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
    .anticipate {
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
      background-color: #EF3F46;
      color: #fff;
      font-size: 12px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

